import Head from 'next/head';

export default function Main() {
	return (
		<>
			<Head>
				<title>Anna!</title>
			</Head>
			<p>Anna!</p>
		</>
	);
}
